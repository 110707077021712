import { SetStateAction, useState } from 'react';
import {
  ListItem,
  useTheme,
  useMediaQuery,
  Grid,
} from '@mui/material';
import Icon from '@/components/atoms/icon';
import { stringHasBlackboard, replaceBlackboardWithClassRoomValueIfNeeded } from '@/myphoenix/utils/classroom-functions';
import { SearchBoxGrid,
  SearchBoxContainer,
  SearchBoxLabel,
  SearchBoxInput,
  SearchBoxIconButton,
  TopSearchesGrid,
  TopSearchesHeading,
  TopSearchesLink,
  TopSearchesList } from '@/components/molecules/search-box-home/SearchBoxHome.styles';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { useGetProgramsQuery } from '@/store/queries/programs';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import TopSearchLinks from './constants';

function SearchBoxHome() {
  const { data: { primaryProgram } = {} } = useGetProgramsQuery(
    { personId: getPersonIdFromSessionStorage() },
  );

  const componentName = 'SearchBox';
  const isCBEDA = primaryProgram?.cbeDaProgram === 'TRUE';
  const [message, setMessage] = useState('');
  const searchActionUrl = `/public/search.html?query=${message}`;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setMessage(event.target.value);
  };

  return (
    <Grid container spacing={2} data-component={componentNameToMyPhxKebabFormat(componentName)}>
      <SearchBoxGrid item xs={12} sm={12} md={12}>
        <form role="search" method="post" target="_blank" action={searchActionUrl}>
          <SearchBoxContainer variant="filled">
            <SearchBoxLabel
              htmlFor="home-page-search-text-input"
            >
              Search Knowledgebase
            </SearchBoxLabel>
            <SearchBoxInput
              id="home-page-search-text-input"
              fullWidth
              onChange={handleChange}
              disableUnderline
              endAdornment={(
                <SearchBoxIconButton
                  id="home-page-search-button"
                  type="submit"
                  aria-label="Search Knowledgebase"
                >
                  <Icon icon="icon-spy-glass" id="home-page-search-spy-glass" />
                </SearchBoxIconButton>
              )}
            />
          </SearchBoxContainer>
        </form>
      </SearchBoxGrid>
      <TopSearchesGrid
        item
        sm={12}
        md={12}
      >
        {mobile
        && (
          <>
            <TopSearchesHeading>
              Top Searches
            </TopSearchesHeading>
            <TopSearchesList id="topSearchesList">
              {TopSearchLinks.map((link, index) => {
                const { label, url } = link;
                const linkText = stringHasBlackboard(label)
                  ? replaceBlackboardWithClassRoomValueIfNeeded(isCBEDA, label) : label;
                const linkUrl = stringHasBlackboard(url)
                  ? replaceBlackboardWithClassRoomValueIfNeeded(isCBEDA, url) : url;

                const addComma = (index !== TopSearchLinks.length - 1) ? ', ' : '';
                return (
                  <ListItem key={linkText}>
                    <TopSearchesLink
                      href={linkUrl}
                    >
                      {linkText}
                    </TopSearchesLink>
                    {addComma}
                  </ListItem>
                );
              })}
            </TopSearchesList>
          </>
        )}
        {!mobile
        && (
          <div>
            <TopSearchesHeading>
              Top Searches
            </TopSearchesHeading>
            {TopSearchLinks.map((link, index) => {
              const { label, url } = link;
              const linkText = stringHasBlackboard(label)
                ? replaceBlackboardWithClassRoomValueIfNeeded(isCBEDA, label) : label;
              const linkUrl = stringHasBlackboard(url)
                ? replaceBlackboardWithClassRoomValueIfNeeded(isCBEDA, url) : url;

              const addComma = (index !== TopSearchLinks.length - 1) ? ', ' : '';
              return (
                <span key={linkText}>
                  <TopSearchesLink
                    href={linkUrl}
                  >
                    {linkText}
                  </TopSearchesLink>
                  {addComma}
                </span>
              );
            })}
          </div>
        )}
      </TopSearchesGrid>
    </Grid>
  );
}
export default SearchBoxHome;
